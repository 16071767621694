@import "../config";

.boxSizeControl {
  display: flex;
  input{
    padding-right: 2px;
  }

  & > div {
    width: 80px;
    margin: auto 10px;
  }
}

.filterContainer label{
  margin-bottom: 0;
}

#report-1 {

  table {
    width: 100%;
    // width: fit-content;
    font-size: 1.15em;
    border: 0px;
    margin-top: 10px;

    th {
      border-left: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      // font-size: 11px;
      height: 50px;
      text-align: center;
      // width: 16.6%;
      // width: fit-content;
      color: #fff;
      background-color: $thead-bg-color;
      padding: 5px 15px;

      &:first-child {
        border-left: 0px;
      }
      &:last-child {
        border-right: 0px;
      }
    }

    td {
      vertical-align: top;
    }
  }

  .holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .box {
      background-color: #efefef;
      // width: 181px;
      word-break: break-word;
      box-sizing: border-box;
      resize: horizontal;
      overflow: hidden;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 5px;
      // @include boxShadow();
      box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49);

      strong {
        line-height: 1.4rem;
        margin-bottom: 3px;
        display: flex;
        width: 100%;
        font-variant-ligatures: none;

        .footnotesIndex {
          font-weight: normal;
          // color: red;
          font-size: 0.9em;
          margin-top: -2px;
        }
      }
      p:first-of-type {
        font-style: italic;
      }
      p {
        line-height: 1.3rem;
        margin: 3px 0;
        font-size: 1.1em;
        
      }
      .badge {
        // color: red;
        font-weight: normal;
        white-space: unset;
        text-align: left;
        display: inline-block;
      }
    }
  }

  .left-behind {
    table {
      th {
        font-size: 0.8rem;
      }
      td {
        padding: 0;
      }
    }
  }
}

@media print {
  .controls {
    display: none !important;
  }
  .badge {
    border: none;
  }
  #report-1 {
    thead {
      break-inside: auto;
    }
  }
}
