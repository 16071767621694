@font-face {
  font-family: "Calibri";
  src: url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot");
  src: url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff") format("woff"), url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.svg#Calibri") format("svg"); }

.hidden {
  visibility: hidden; }

body {
  margin: 0;
  padding: 0;
  color: #323232;
  font-family: Calibri, 'Poppins' Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  color: inherit; }
  a:hover {
    text-decoration: none; }

button:focus {
  outline: none; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 18px; }

h4 {
  font-size: 14px; }

.body-text1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px; }

.body-text2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px; }

@font-face {
    font-family: PoppinsSemiBold;
    src: url(/static/media/Poppins-SemiBold.4cdacb8f.ttf);
}

.listItemText {
font-family: PoppinsSemiBold;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CCC3C3;
    margin-right: 10px;
}

.listItemSelected {
    color: #b22028;
}

.listItemSelected .dot{
    background: #b22028;
}

.drawerTooltip {
    font-size: 2em;
    width:auto;
}

.Mui-selected {
    color: #b22028!important;
}

.Mui-selected .dot{
    background: #b22028;
}

.hidden {
  visibility: hidden; }

.modalHeader {
  text-align: center;
  position: relative;
  color: #b22028; }
  @media (max-width: 960px) {
    .modalHeader h1 {
      font-size: 15px; } }
  .modalHeader > svg {
    display: none;
    position: absolute;
    right: 15px;
    top: 5px;
    background: #b22028;
    border-radius: 50%;
    color: white;
    padding: 3px; }
  .modalHeader.withClose svg {
    display: block; }

.modalHeader[disabled] > svg {
  display: inline;
  position: absolute;
  right: 15px;
  top: 5px;
  color: white;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  color: white;
  padding: 3px; }

.screen-icon-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

b.atacana-label {
  margin-bottom: 5px;
  display: block; }

input.atacana, select.atacana {
  display: block;
  width: 100%;
  padding: 10px;
  background: #F9F6F6;
  border: 1px solid #DDDADA;
  box-sizing: border-box;
  font-size: 15px;
  border-radius: 4px; }

.modalFooter {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
  -webkit-justify-content: center;
          justify-content: center; }
  .modalFooter button {
    margin: 0 10px; }
  @media (max-width: 960px) {
    .modalFooter button {
      padding: 5px 10px;
      font-size: 10px; } }

.userInformationCard {
  width: 600px;
  padding: 25px; }
  @media (max-width: 960px) {
    .userInformationCard {
      font-size: 10px; } }

.permissionsInformationCard {
  width: 600px;
  padding: 25px; }
  @media (max-width: 960px) {
    .permissionsInformationCard {
      font-size: 10px; } }

.deleteConfirmCard, .userUndoCard {
  width: 550px;
  padding: 25px;
  font-family: 'Poppins'; }
  .deleteConfirmCard .modalHeader, .userUndoCard .modalHeader {
    color: #000;
    color: initial; }
    .deleteConfirmCard .modalHeader img, .userUndoCard .modalHeader img {
      margin-bottom: 10px; }
  .deleteConfirmCard b, .userUndoCard b {
    text-align: center;
    display: block;
    font-size: 16px; }
  .deleteConfirmCard h3, .userUndoCard h3 {
    text-align: center;
    color: #000;
    color: initial; }

.modalButton {
  border: none;
  border-radius: 3px;
  padding: 8px 25px;
  border: 2px solid #b22028;
  color: #b22028;
  background-color: inherit;
  text-transform: uppercase;
  line-height: normal;
  line-height: initial;
  font-weight: 600; }
  .modalButton svg {
    display: inline-block; }
  .modalButton.fill {
    color: white;
    background-color: #b22028; }

.fullName {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  color: #b22028; }

.text-center {
  text-align: center; }

.containerDateRange {
  overflow: hidden;
  position: absolute;
  z-index: 1000;
  -webkit-transform: translate(5%, 60px);
          transform: translate(5%, 60px);
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px; }

.skeleton-p span span {
  margin: 1%; }

.ta-screen-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #323232; }

.optionsContainer {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer; }

.optionsLink:hover {
  color: #B22028; }

.addTherapAreaTitle {
  font-weight: 600;
  font-family: Poppins; }

.optionTag {
  font-family: Poppins;
  margin-left: 10px; }

.optionsPopover {
  padding: 5px;
  max-width: 220px; }

.card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  margin-top: 12px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; }

.background-single-category {
  background-color: #B22028;
  border-radius: 0px 0px 6px 6px; }

.edit-delete {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  left: 87%;
  top: 26px; }

.single-category {
  position: relative;
  display: block;
  min-height: 231px;
  color: #fff;
  box-shadow: 0px 24px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 20px;
  cursor: pointer; }
  .single-category .catActions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    -webkit-justify-content: center;
            justify-content: center;
    transition: 0.4s ease-in; }
  .single-category a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #B22028;
    height: 30%;
    -webkit-align-items: center;
            align-items: center;
    transition: 0.5s;
    border-radius: 6px 6px 0px 0px; }
    .single-category a h4 {
      border: 2px solid #fff;
      border-color: transparent;
      padding: 15px;
      transition: 0.4s; }
    .single-category a:hover {
      color: inherit; }
      .single-category a:hover h4 {
        border-color: #fff; }

.single-category:hover > a {
  background: #7F1E22; }

.single-category:hover > .background-single-category {
  background: black;
  -webkit-text-decoration: underline white;
          text-decoration: underline white; }

.single-category:hover > .edit-delete {
  visibility: visible; }

.hidden {
  visibility: hidden; }

.section-header {
  padding-bottom: 20px; }
  .section-header .section-left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .section-header .section-right {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center; }

.layout-toolbar {
  padding: 15px 0; }

.toolbar-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }
  .toolbar-right > * {
    margin-left: 10px !important; }

.layout-content {
  padding: 20px 0; }

.scrollable-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

#toImage {
  font-size: 18px;
  line-height: 20px; }

.export {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-content: center;
          align-content: center; }
  .export button {
    font-weight: 600; }
  .export .form-group {
    width: 200px;
    margin: auto 10px; }
  .export .export-image {
    color: white;
    border-style: none;
    height: 3rem;
    width: 10rem;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: medium; }

#footnotes-check {
  float: right; }

.footnotes {
  display: -webkit-flex;
  display: flex; }
  .footnotes a {
    color: blue; }
  .footnotes ul {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    list-style-type: none; }
    .footnotes ul p {
      margin: 0;
      font-size: 0.95rem; }
    .footnotes ul.horizontal li {
      display: inline;
      margin: 0 5px; }
      .footnotes ul.horizontal li:first-child {
        margin-left: 0; }
      .footnotes ul.horizontal li p {
        display: inline; }

.hidden {
  visibility: hidden; }

.boxSizeControl {
  display: -webkit-flex;
  display: flex; }
  .boxSizeControl input {
    padding-right: 2px; }
  .boxSizeControl > div {
    width: 80px;
    margin: auto 10px; }

.filterContainer label {
  margin-bottom: 0; }

#report-1 table {
  width: 100%;
  font-size: 1.15em;
  border: 0px;
  margin-top: 10px; }
  #report-1 table th {
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    height: 50px;
    text-align: center;
    color: #fff;
    background-color: #3f3f3f;
    padding: 5px 15px; }
    #report-1 table th:first-child {
      border-left: 0px; }
    #report-1 table th:last-child {
      border-right: 0px; }
  #report-1 table td {
    vertical-align: top; }

#report-1 .holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }
  #report-1 .holder .box {
    background-color: #efefef;
    word-break: break-word;
    box-sizing: border-box;
    resize: horizontal;
    overflow: hidden;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 5px;
    box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49); }
    #report-1 .holder .box strong {
      line-height: 1.4rem;
      margin-bottom: 3px;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-font-feature-settings: none;
              font-feature-settings: none;
      -webkit-font-variant-ligatures: none;
              font-variant-ligatures: none; }
      #report-1 .holder .box strong .footnotesIndex {
        font-weight: normal;
        font-size: 0.9em;
        margin-top: -2px; }
    #report-1 .holder .box p:first-of-type {
      font-style: italic; }
    #report-1 .holder .box p {
      line-height: 1.3rem;
      margin: 3px 0;
      font-size: 1.1em; }
    #report-1 .holder .box .badge {
      font-weight: normal;
      white-space: unset;
      text-align: left;
      display: inline-block; }

#report-1 .left-behind table th {
  font-size: 0.8rem; }

#report-1 .left-behind table td {
  padding: 0; }

@media print {
  .controls {
    display: none !important; }
  .badge {
    border: none; }
  #report-1 thead {
    page-break-inside: auto;
    -webkit-column-break-inside: auto;
            break-inside: auto; } }

.hidden {
  visibility: hidden; }

#report-1-tm table {
  width: 100%;
  font-size: 1.15em;
  border: 0px;
  margin-top: 10px; }
  #report-1-tm table th {
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    height: 50px;
    text-align: center;
    color: #fff;
    background-color: #3f3f3f;
    padding: 5px 15px; }
    #report-1-tm table th:first-child {
      border-left: 0px; }
    #report-1-tm table th:last-child {
      border-right: 0px; }
  #report-1-tm table td {
    vertical-align: top; }

#report-1-tm .holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }
  #report-1-tm .holder .box {
    background-color: #efefef;
    width: 185px;
    display: -webkit-flex;
    display: flex;
    resize: horizontal;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 5px;
    box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49); }
    #report-1-tm .holder .box .box-texts {
      word-break: break-word; }
    #report-1-tm .holder .box strong {
      line-height: 1.4rem;
      margin-bottom: 3px;
      display: -webkit-flex;
      display: flex; }
      #report-1-tm .holder .box strong .footnotesIndex {
        font-weight: normal;
        font-size: 0.9em;
        margin-top: -2px; }
    #report-1-tm .holder .box p:first-of-type {
      font-style: italic; }
    #report-1-tm .holder .box p {
      line-height: 1.3rem;
      margin: 3px 0;
      font-size: 1.1em; }
    #report-1-tm .holder .box .badge {
      font-weight: normal;
      white-space: unset;
      text-align: left;
      display: inline-block; }
    #report-1-tm .holder .box .tm-container {
      width: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      -webkit-align-items: flex-end;
              align-items: flex-end; }
      #report-1-tm .holder .box .tm-container img {
        margin: 5px -8px 5px 0;
        width: 25px; }

#report-1-tm .left-behind table th {
  font-size: 0.8rem; }

#report-1-tm .left-behind table td {
  padding: 0; }

@media print {
  .controls {
    display: none !important; }
  .badge {
    border: none; }
  #report-1-tm thead {
    page-break-inside: auto;
    -webkit-column-break-inside: auto;
            break-inside: auto; }
  .holder .box {
    resize: unset !important; } }

.hidden {
  visibility: hidden; }

.header-title {
  border-left: #c00000;
  border-left-style: outset;
  border-width: thick;
  padding-left: 2.5rem;
  height: 5rem;
  padding-top: 1rem;
  border-left-width: 10px;
  /* margin-bottom: 0; */ }
  .header-title h1,
  .header-title h2 {
    margin: 0;
    font-weight: normal;
    font-size: 2rem; }
  .header-title h2 {
    font-size: 1.5rem; }

#report-2 .layout-content {
  padding: 0; }

#report-2 #toImage {
  padding: 5px 5px 0px 5px; }

#report-2 .table-container {
  overflow-x: scroll;
  margin-top: 5px; }

#report-2 .gridTable {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-template-rows: -webkit-min-content minmax(auto, auto);
  grid-template-rows: min-content minmax(auto, auto);
  grid-column-gap: 5px; }
  #report-2 .gridTable .sideBar {
    background-color: red;
    border-bottom: 2px solid #ddd;
    position: relative;
    color: #fff;
    line-height: 1.4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5); }
    #report-2 .gridTable .sideBar p {
      margin: 10px 0;
      -webkit-text-orientation: sideways;
              text-orientation: sideways;
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      width: -webkit-min-content;
      width: min-content;
      text-align: center;
      word-break: break-word; }
  #report-2 .gridTable .gtHeaders {
    display: -webkit-flex;
    display: flex;
    color: #fff; }
    #report-2 .gridTable .gtHeaders > div {
      width: -webkit-fill-available;
      width: stretch;
      text-align: center;
      padding: 1rem;
      margin: 2px;
      background-color: #3f3f3f;
      font-size: 1.01em;
      font-weight: bold; }
  #report-2 .gridTable .segments {
    border-bottom: 1px solid #ddd;
    resize: vertical;
    overflow: hidden; }
  #report-2 .gridTable .gtRow {
    display: -webkit-flex;
    display: flex;
    width: -webkit-fill-available;
    width: stretch;
    padding-bottom: 5px; }
    #report-2 .gridTable .gtRow > div {
      overflow: visible;
      padding: 2px; }
      #report-2 .gridTable .gtRow > div .info {
        display: -webkit-flex;
        display: flex;
        resize: horizontal;
        overflow: hidden;
        min-height: 45px;
        width: -webkit-max-content;
        width: max-content;
        max-width: -webkit-max-content;
        max-width: max-content;
        word-break: break-all; }
        #report-2 .gridTable .gtRow > div .info .marker-container {
          padding: 3px; }
        #report-2 .gridTable .gtRow > div .info p {
          margin: 0;
          text-align: left;
          margin-left: 5px;
          font-weight: bold; }
          #report-2 .gridTable .gtRow > div .info p .product-company {
            color: #000;
            display: block;
            width: 100%; }
            #report-2 .gridTable .gtRow > div .info p .product-company .company-name {
              color: inherit; }
          #report-2 .gridTable .gtRow > div .info p span {
            font-size: 0.99em;
            color: gray; }
      #report-2 .gridTable .gtRow > div.quarter-0 .info {
        margin-left: calc( (100% / 13) * 0); }
      #report-2 .gridTable .gtRow > div.quarter-1 .info {
        margin-left: calc( (100% / 13) * 1); }
      #report-2 .gridTable .gtRow > div.quarter-2 .info {
        margin-left: calc( (100% / 13) * 2); }
      #report-2 .gridTable .gtRow > div.quarter-3 .info {
        margin-left: calc( (100% / 13) * 3); }
      #report-2 .gridTable .gtRow > div.quarter-4 .info {
        margin-left: calc( (100% / 13) * 4); }
      #report-2 .gridTable .gtRow > div.quarter-5 .info {
        margin-left: calc( (100% / 13) * 5); }
      #report-2 .gridTable .gtRow > div.quarter-6 .info {
        margin-left: calc( (100% / 13) * 6); }
      #report-2 .gridTable .gtRow > div.quarter-7 .info {
        margin-left: calc( (100% / 13) * 7); }
      #report-2 .gridTable .gtRow > div.quarter-8 .info {
        margin-left: calc( (100% / 13) * 8); }
      #report-2 .gridTable .gtRow > div.quarter-9 .info {
        margin-left: calc( (100% / 13) * 9); }
      #report-2 .gridTable .gtRow > div.quarter-10 .info {
        margin-left: calc( (100% / 13) * 10); }
      #report-2 .gridTable .gtRow > div.quarter-11 .info {
        margin-left: calc( (100% / 13) * 11); }
      #report-2 .gridTable .gtRow > div.toLeft {
        direction: rtl; }
        #report-2 .gridTable .gtRow > div.toLeft .info {
          margin-left: 0 !important;
          min-width: 100px; }
          #report-2 .gridTable .gtRow > div.toLeft .info p {
            text-align: right;
            margin-right: 5px;
            margin-left: 0px; }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-0 .info {
          margin-right: calc( (100% / 13) * (11 - 0)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-1 .info {
          margin-right: calc( (100% / 13) * (11 - 1)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-2 .info {
          margin-right: calc( (100% / 13) * (11 - 2)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-3 .info {
          margin-right: calc( (100% / 13) * (11 - 3)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-4 .info {
          margin-right: calc( (100% / 13) * (11 - 4)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-5 .info {
          margin-right: calc( (100% / 13) * (11 - 5)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-6 .info {
          margin-right: calc( (100% / 13) * (11 - 6)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-7 .info {
          margin-right: calc( (100% / 13) * (11 - 7)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-8 .info {
          margin-right: calc( (100% / 13) * (11 - 8)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-9 .info {
          margin-right: calc( (100% / 13) * (11 - 9)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-10 .info {
          margin-right: calc( (100% / 13) * (11 - 10)); }
        #report-2 .gridTable .gtRow > div.toLeft.quarter-11 .info {
          margin-right: calc( (100% / 13) * (11 - 11)); }

#report-2 .left-behind th:nth-child(1) {
  width: 10%;
  border-right: 3px solid #fff;
  background: unset; }

#report-2 .ns-therapy {
  position: absolute;
  top: 1px;
  left: calc(100% + 2px);
  width: 50px;
  height: 40%;
  background-color: red;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
  display: none; }
  #report-2 .ns-therapy span {
    display: inline-block;
    width: 130%;
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    position: absolute;
    top: 35px;
    word-break: break-all;
    text-align: center; }
  #report-2 .ns-therapy::before {
    content: "";
    position: absolute;
    border-top: 50px solid transparent;
    border-left: 50px solid red;
    border-bottom: 50px solid transparent;
    left: 100%; }
  #report-2 .ns-therapy.therapy-2 {
    top: calc(40% + 3px); }

#report-2 .marker {
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(50deg) skew(15deg);
          transform: rotate(50deg) skew(15deg);
  display: inline-block;
  min-width: 25px;
  margin-top: 5px; }
  #report-2 .marker.bordered {
    border: 3px solid red;
    border-left-color: red !important; }
  #report-2 .marker.cutomFormat {
    position: relative;
    margin-right: 21px; }
    #report-2 .marker.cutomFormat::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      top: -8px;
      right: -17px;
      -webkit-transform: rotateY(0deg) rotateZ(-45deg);
              transform: rotateY(0deg) rotateZ(-45deg);
      background-color: inherit; }
    #report-2 .marker.cutomFormat::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -23px;
      right: -25px;
      -webkit-transform: rotateY(0deg) rotateZ(-45deg);
              transform: rotateY(0deg) rotateZ(-45deg);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 12px solid transparent;
      border-left-color: inherit; }

#report-2 .legend {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center; }
  #report-2 .legend .marker {
    margin: auto 0; }
  #report-2 .legend .legendText {
    margin: auto 0;
    margin-left: 10px; }

#report-2 #marker-specs {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  #report-2 #marker-specs .marker-spec {
    display: -webkit-flex;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-align-items: center;
            align-items: center; }
    #report-2 #marker-specs .marker-spec div {
      margin-left: 15px; }
      #report-2 #marker-specs .marker-spec div p {
        margin: 0; }

#report-2 .bg-blue {
  background-color: blue; }

#report-2 .bg-red {
  background-color: red; }

#report-2 .bg-green {
  background-color: #39b050; }

#report-2 .bg-yellow {
  background-color: #f9c102; }

#report-2 .bg-darkred {
  background-color: #c5103d; }

#report-2 .bg-sky {
  background-color: #3fb0ef; }

#report-2 .bg-violet {
  background-color: #ac96ee; }

#report-2 .bg-purple {
  background-color: #7030a0; }

#report-2 .bg-gray {
  background-color: #efefef; }

#report-2 td.center {
  text-align: center; }

#report-2 tr > td:last-of-type {
  text-align: right; }

#report-2 .actionBtn {
  display: flexbox;
  text-align: right; }

#report-2 .actionBtn div {
  display: -webkit-inline-flex;
  display: inline-flex; }

#report-2 .filterContainer {
  margin-left: 10px; }

@media print {
  .filterContainer {
    display: none !important; } }

.hidden {
  visibility: hidden; }

#report-3 thead {
  background-color: #3f3f3f;
  color: white; }

#report-3 th {
  font-weight: normal;
  font-weight: initial;
  min-height: 3rem;
  height: 3rem;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem; }

#report-3 td {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  border-collapse: collapse; }

#report-3 .left-behind table {
  width: 100%; }

#report-3 .left-behind th:last-child {
  text-align: center; }

#report-3 .left-behind td {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
  text-align: center;
  border: 1px solid #ddd; }

#report-3 table {
  border-spacing: 0;
  width: 100%;
  font-size: 1.15em; }

#report-3 tbody tr {
  background-color: #efefef; }

#report-3 .bg-blue {
  background-color: #002060; }

#report-3 .bg-red {
  background-color: #c00000; }

#report-3 .bg-white {
  background-color: white; }

#report-3 span.dot {
  height: 0.7rem;
  width: 0.7rem;
  background-color: green;
  border-radius: 50%;
  display: inline-block; }

#report-3 .footnotesIndex {
  font-size: 0.7em; }

#report-3 td.center {
  text-align: center; }

#report-3 tr > td:last-of-type {
  text-align: right; }

.hidden {
  visibility: hidden; }

#report-4 thead {
  color: white; }

#report-4 th {
  font-weight: normal;
  font-weight: initial;
  height: 2rem;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #3f3f3f;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff; }
  #report-4 th.month-headers {
    width: 9%;
    text-align: center; }

#report-4 th:first-child {
  background-color: #fff; }

#report-4 td {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  border: 1px solid #ddd; }
  #report-4 td.conference {
    background-color: #ddd;
    text-transform: uppercase; }
  #report-4 td.thirdCol span {
    display: block; }
    #report-4 td.thirdCol span:first-child {
      font-weight: bold;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
      #report-4 td.thirdCol span:first-child span.footnotesIndex {
        display: inline-block;
        font-weight: normal; }
        #report-4 td.thirdCol span:first-child span.footnotesIndex span {
          display: inline-block; }
  #report-4 td.segmentTd {
    padding: 10px 10px 0 10px; }
    #report-4 td.segmentTd div {
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; }
    #report-4 td.segmentTd p {
      margin: auto;
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      font-weight: bold;
      font-style: italic;
      text-align: center; }
    #report-4 td.segmentTd span {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      display: block;
      line-height: 0.7rem; }
  #report-4 td.event {
    background-color: #f4cccc;
    font-weight: bold;
    padding: 0.3rem; }
  #report-4 td.event-continue-line {
    position: relative; }
    #report-4 td.event-continue-line::after {
      content: "-----------------";
      color: #3f3f3f;
      font-size: 1rem;
      letter-spacing: 5px;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: 5px;
      line-height: 2px;
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0; }
    #report-4 td.event-continue-line.end::before {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: calc(50% - 9px);
      right: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 12px solid #696969; }
  #report-4 td.companyLogos {
    padding: 0 0.5rem; }
    #report-4 td.companyLogos img {
      max-width: 75px;
      margin: 5px 0; }
    #report-4 td.companyLogos span {
      display: block;
      margin: 5px 0; }

#report-4 table {
  border-spacing: 0;
  width: 100%;
  font-size: 1.20em; }

#report-4 tbody tr {
  background-color: #fff; }

#report-4 .bg-blue {
  background-color: #002060; }

#report-4 .bg-red {
  background-color: #c00000; }

#report-4 .bg-white {
  background-color: white; }

#report-4 td.center {
  text-align: center; }

#report-4 .actionBtn {
  display: flexbox;
  text-align: right; }

#report-4 .actionBtn div {
  display: -webkit-inline-flex;
  display: inline-flex; }

#report-4 .filterContainer {
  margin-left: 10px; }
  #report-4 .filterContainer label {
    margin-bottom: 0; }

#report-4 #footnotes-check {
  float: right; }

#report-4 .footnotes {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  #report-4 .footnotes .eventTypes {
    min-width: 480px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
            align-content: flex-start; }
    #report-4 .footnotes .eventTypes p {
      width: 150px;
      text-align: center;
      margin: 5px;
      padding: 5px;
      height: 60px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }

#report-4 .left-behind table th:first-child {
  background-color: #3f3f3f; }

#report-4 .left-behind table td {
  padding: 0; }

.hidden {
  visibility: hidden; }

.news-module-wrapper .admin-base .section-left a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.news-module-wrapper .admin-base .toolbar-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.news-date-range-dialog .MuiDialog-paper {
  margin: 0px; }

@media (max-width: 1280px) {
  .NewsTable {
    margin: 20px; } }

.news-filter {
  width: 100%;
  text-align: end; }

.hidden {
  visibility: hidden; }

.news-loader {
  width: 95%;
  margin-top: 20px;
  box-sizing: border-box; }
  .news-loader span span {
    margin: 1%; }

.newsContainer {
  margin-top: 15px; }

.attachments-wrapper {
  margin: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.attachment-wrapper {
  margin: 0 10px;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.attachment-icon {
  color: #8AC7CA;
  margin: 0 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 16px; }

.attachment-name {
  color: #8A8A8A;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }

div.rowcard {
  background-color: white;
  box-shadow: black;
  width: 70%;
  position: relative;
  /* height: 30vh; */
  box-shadow: 0px 4px 4px rgba(217, 212, 212, 0.25);
  border-radius: 9px;
  margin-top: 20px;
  padding: 20px;
  font-size: 1rem; }
  @media (max-width: 600px) {
    div.rowcard {
      font-size: 0.8rem; } }

/*@media only screen and (max-width: 600px) {
  div.rowcard{
    width: 100%; 
  }
  .icon-title{
    display: none;
  }
}*/
div.rowcard {
  width: 100%; }

@media only screen and (max-width: 600px) {
  .news-date {
    display: none; }
  .card-footer-xs {
    display: block;
    padding-top: 20px; }
  .news-date-xs {
    color: #8b8b8b; } }

@media only screen and (min-width: 600px) {
  .card-footer-xs {
    display: none; } }

.datePicker {
  margin: 16px 0; }

span.line {
  width: 95%;
  display: block;
  margin: 14px 0;
  height: 1px;
  border-top: solid 1px #D8D0D0; }

span.line-short {
  width: 15%;
  display: block;
  margin: 14px 0;
  height: 1px;
  border-top: solid 1px #D8D0D0; }

.icon-title-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row; }

.icon-title {
  margin-right: 10px;
  cursor: pointer; }

.attachments-legend {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px; }

.comentary {
  margin: 10px 0;
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  color: #4D4F54;
  line-height: 24px; }

.sources-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #F4F4F4;
  border-radius: 5px; }

.source-name {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #B22028;
  text-decoration: underline;
  margin: 0 5px; }

.news-date {
  font-family: Poppins;
  color: #323232;
  font-weight: 500;
  font-size: 14px;
  padding-top: 5px; }

.sources-legend {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4D4F54;
  margin: 0 10px; }

.sourcelink-icon-wrapper {
  margin: 0 5px;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 18%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.news-title {
  color: #4D4F54;
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins;
  margin-right: 10px !important; }

.rowcard .second {
  color: #323232; }
  .rowcard .second div {
    font-weight: 100;
    font-weight: normal;
    width: 90%;
    line-height: 24px; }
    @media (max-width: 600px) {
      .rowcard .second div {
        width: 100%; } }
  .rowcard .second .indication {
    display: -webkit-flex;
    display: flex;
    margin-right: 5px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
    color: #8A8A8A;
    margin: 1rem 0; }
    @media (max-width: 600px) {
      .rowcard .second .indication {
        margin: 0 1rem; } }
  .rowcard .second .nct {
    color: #b22028;
    text-decoration: underline;
    font-weight: bolder; }
    .rowcard .second .nct a:hover {
      color: inherit; }

.diseases {
  font-weight: 600;
  color: #8A8A8A;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px; }

.compounds-companies-priority {
  font-family: Poppins;
  color: #8A8A8A;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px; }

.news-category {
  margin: 10px 0; }
  .news-category h5 {
    font-weight: bold;
    margin: 0; }
    @media (max-width: 600px) {
      .news-category h5 {
        font-size: 1rem; } }

.title-date-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.content-comentary-hidden-wrapper {
  display: none; }

.content-comentary-visible-wrapper {
  display: block; }

.expand-icon-wapper {
  position: relative;
  margin-left: 34%;
  top: -21px; }

.expand-icon {
  color: white;
  background-color: #CCC3C3;
  border-radius: 50%; }
  .expand-icon:hover {
    cursor: pointer;
    background-color: #B22028; }

.date-more-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row; }

.compound-icon-wrapper {
  display: -webkit-flex;
  display: flex;
  margin-right: 5px;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.seen-icon-wrapper {
  display: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  color: #56C6CC; }

.seen-icon-disabled-wrapper {
  display: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 16px;
  -webkit-align-items: center;
          align-items: center;
  color: #CCC3C3; }

.fav-icon-wrapper {
  display: none;
  font-size: 16px;
  color: #B22028; }

.fav-icon-disabled-wrapper {
  display: none;
  font-size: 16px;
  color: #CCC3C3; }

.rdrDefinedRangesWrapper {
  width: auto; }

.shownews {
  margin-top: 17px; }
  .shownews p {
    margin: 0; }
  .shownews h1 {
    font-size: 24px; }

.link-icon {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 16px; }

.rowcard a.source_link {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  color: white; }
  @media (max-width: 600px) {
    .rowcard a.source_link {
      padding: 5px; } }

.search-container {
  display: -webkit-flex;
  display: flex;
  background: #fff;
  padding: 10px;
  width: 90%;
  margin: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-width: 98%; }
  .search-container .search-button {
    margin: auto 10px auto 0;
    cursor: pointer; }
  .search-container .search-text {
    border: none;
    background: transparent;
    transition: 0.2s ease-in;
    width: -webkit-fill-available;
    width: stretch; }
    .search-container .search-text:focus {
      padding-left: 10px;
      outline: none; }
  .search-container .clear-icon {
    margin-top: 2px;
    cursor: pointer; }

@media only screen and (max-width: 768px) {
  .search-container {
    margin-bottom: 10px; } }

.hidden {
  visibility: hidden; }

.admin-base .toolbar-right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .admin-base .toolbar-right .search-container {
    margin: auto 0 auto 20px;
    width: 70%; }

a.menu {
  margin: auto 5px; }
  a.menu.active button {
    background-color: #b22028;
    color: white; }

.buttons-container {
  margin: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.combo-boxes {
  width: 100%; }

.form-title {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2px;
  color: #8A8A8A; }

.form-icon-image {
  margin-right: 15px; }

.form-icon-title-container {
  -webkit-flex-direction: row;
          flex-direction: row;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 15px 0; }

a.generic_table_logos img {
  max-width: 50px; }

#filterButton span#counter {
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #fff;
  background-color: #b22028;
  border-radius: 50%;
  position: relative;
  right: -2px;
  top: -5px; }

.date-picker-news {
  display: inline;
  display: initial; }

.hidden {
  visibility: hidden; }

.headAdvance {
  background-color: #b22028;
  color: white;
  padding: 3px 20px; }

.horizontal-line {
  width: 100%;
  height: 100px;
  border: 0;
  border-bottom: solid 1px grey;
  margin-bottom: 20px; }

.filterActions {
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px 0; }

button.filterButton {
  border: none;
  border-radius: 3px;
  padding: 5px 25px;
  border: 2px solid #b22028;
  text-transform: uppercase;
  color: #b22028;
  background-color: inherit; }
  button.filterButton svg {
    display: inline-block; }
  button.filterButton.red {
    color: white;
    background-color: #b22028; }

.filterModal {
  overflow-y: auto !important;
  max-width: 600px;
  max-height: 100vh;
  padding: 15px; }
  .filterModal .filterContainer {
    border: none;
    max-width: 100%;
    margin: 0; }
  @media (max-width: 960px) {
    .filterModal {
      max-width: 90vw;
      height: auto;
      max-height: 100vh; } }
  .filterModal .MuiOutlinedInput-notchedOutline {
    border: 1px solid #DDDADA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); }
  .filterModal .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #DDDADA; }
  .filterModal .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #DDDADA; }

.hidden {
  visibility: hidden; }

#report-5 .admin-base hr, #report-5 .admin-base .section-left a {
  display: none; }

#report-5 .admin-base .section-header {
  height: 0;
  padding: 0; }

#report-5 .admin-base .section-right {
  -webkit-transform: translateY(-155%);
          transform: translateY(-155%); }

#report-5 .no-data {
  padding: 20px;
  text-align: center;
  font-style: italic;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

#report-5 #newsGlobalTitle {
  display: none; }

#report-5 .news_filters div.indication * {
  display: none; }

#report-5 #dateRangeContainer {
  margin-left: auto;
  margin-right: auto; }

.hidden {
  visibility: hidden; }

#report-6 #toImage {
  margin-bottom: 20px;
  font-size: 16px;
  padding: 20px; }

#report-6 table {
  width: 100%;
  font-size: 1.15em;
  border: 0px; }
  #report-6 table th {
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    height: 50px;
    text-align: center;
    color: #fff;
    background-color: #3f3f3f;
    padding: 5px 15px; }
    #report-6 table th:first-child {
      border-left: 0px;
      background-color: transparent; }
    #report-6 table th:last-child {
      border-right: 0px; }
  #report-6 table .spacer {
    height: 10px; }
    #report-6 table .spacer td {
      vertical-align: middle; }
    #report-6 table .spacer div {
      border-bottom: dashed red;
      border-width: 2px;
      width: 100%;
      margin-top: 10px; }
    #report-6 table .spacer:last-child div {
      display: none; }
  #report-6 table td {
    vertical-align: top; }
  #report-6 table td.lot-row {
    vertical-align: middle;
    border: 1px solid black;
    width: 5px; }
    #report-6 table td.lot-row .holder {
      width: 35px;
      -webkit-justify-content: center;
              justify-content: center; }
    #report-6 table td.lot-row span {
      display: inline-block;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      font-size: 1.3rem;
      font-weight: bold; }

#report-6 .holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  #report-6 .holder .box {
    background-color: #efefef;
    width: 185px;
    display: -webkit-flex;
    display: flex;
    resize: horizontal;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 5px;
    box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49);
    border: 1px solid rgba(132, 127, 127, 0.49); }
    #report-6 .holder .box.withBorder {
      border: 1px solid orange; }
    #report-6 .holder .box .box-texts {
      word-break: break-word;
      width: 100%; }
      #report-6 .holder .box .box-texts img {
        width: 25px;
        height: 25px;
        display: block;
        float: right;
        clear: both;
        margin: 2px -8px 2px 5px; }
    #report-6 .holder .box strong {
      line-height: 1.4rem;
      margin-bottom: 3px;
      display: -webkit-flex;
      display: flex; }
      #report-6 .holder .box strong .footnotesIndex {
        font-weight: normal;
        font-size: 0.9em;
        margin-top: -2px; }
    #report-6 .holder .box p:first-of-type {
      font-style: italic; }
      #report-6 .holder .box p:first-of-type strong {
        display: inline; }
    #report-6 .holder .box p {
      line-height: 1.3rem;
      margin: 3px 0;
      font-size: 1.1em; }
    #report-6 .holder .box .badge {
      font-weight: bold;
      white-space: unset;
      text-align: left;
      display: inline-block; }
    #report-6 .holder .box .tm-container {
      width: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      margin-left: auto; }
      #report-6 .holder .box .tm-container img {
        margin: 5px -8px 5px 0;
        width: 25px;
        height: 25px; }

#report-6 .left-behind table th {
  font-size: 0.8rem; }

#report-6 .left-behind table td {
  padding: 0; }

.biomarkers-logo {
  background-color: #266685;
  border-radius: 50%;
  padding: 5px; }

.logo-legends img {
  width: 40px;
  height: 40px; }

.logo-legends .legendText {
  margin: auto 0 auto 5px;
  text-transform: capitalize; }

.colorBox {
  margin: 10px;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .colorBox .legendText {
    color: white;
    margin: auto 0; }

@media print {
  .controls {
    display: none !important; }
  .badge {
    border: none; }
  #report-6 thead {
    page-break-inside: auto;
    -webkit-column-break-inside: auto;
            break-inside: auto; }
  .holder .box {
    resize: unset !important; } }

.hidden {
  visibility: hidden; }

#report-8 .filters label {
  margin-bottom: 0; }

#report-8 #toImage {
  margin-bottom: 20px;
  font-size: 16px; }

#report-8 table {
  width: 100%;
  font-size: 1.15em;
  border: 0px;
  margin-top: 10px; }
  #report-8 table th {
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    height: 50px;
    text-align: center;
    color: #fff;
    background-color: #3f3f3f;
    padding: 5px 15px;
    text-transform: capitalize; }
    #report-8 table th:first-child {
      border-left: 0px; }
    #report-8 table th:last-child {
      border-right: 0px; }
  #report-8 table .spacer {
    height: 10px; }
    #report-8 table .spacer td {
      vertical-align: middle; }
    #report-8 table .spacer div {
      border-bottom: dashed red;
      border-width: 2px;
      position: absolute;
      width: 100%;
      margin-top: 5px; }
    #report-8 table .spacer:last-child div {
      display: none; }
  #report-8 table td {
    vertical-align: top; }
  #report-8 table td.lot-row {
    vertical-align: middle;
    border: 1px solid black; }
    #report-8 table td.lot-row span {
      display: inline-block;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      font-size: 1.3rem;
      font-weight: bold; }

#report-8 .holder {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }
  #report-8 .holder .box {
    background-color: #efefef;
    width: 185px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    resize: horizontal;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 5px;
    box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49);
    border: 1px solid rgba(132, 127, 127, 0.49); }
    #report-8 .holder .box .box-texts {
      word-break: break-word; }
    #report-8 .holder .box strong {
      line-height: 1.4rem;
      margin-bottom: 3px;
      display: -webkit-flex;
      display: flex; }
      #report-8 .holder .box strong .footnotesIndex {
        font-weight: normal;
        font-size: 0.9em;
        margin-top: -2px; }
    #report-8 .holder .box p:first-of-type {
      font-style: italic; }
    #report-8 .holder .box p {
      line-height: 1.3rem;
      margin: 3px 0;
      font-size: 1.1em; }
    #report-8 .holder .box .badge {
      font-weight: bold;
      white-space: unset;
      text-align: left;
      display: inline-block; }
    #report-8 .holder .box .tm-container {
      width: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      -webkit-align-items: flex-end;
              align-items: flex-end; }
      #report-8 .holder .box .tm-container img {
        margin: 5px -8px 5px 0;
        width: 25px;
        height: 25px; }

#report-8 .left-behind table th {
  font-size: 0.8rem; }

#report-8 .left-behind table td {
  padding: 0; }

.biomarkers-logo {
  background-color: #266685;
  border-radius: 50%;
  padding: 5px; }

.logo-legends img {
  width: 40px;
  height: 40px; }

.logo-legends .legendText {
  margin: auto 0 auto 5px;
  text-transform: capitalize; }

.colorBox {
  margin: 10px;
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .colorBox .legendText {
    color: white;
    margin: auto 0; }

@media print {
  .controls {
    display: none !important; }
  .badge {
    border: none; }
  #report-8 thead {
    page-break-inside: auto;
    -webkit-column-break-inside: auto;
            break-inside: auto; }
  .holder .box {
    resize: unset !important; } }

.hidden {
  visibility: hidden; }

#report-9 .header-title {
  height: 4rem; }

#report-9 #toImage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: -webkit-max-content;
  width: max-content;
  min-width: 100%; }

#report-9 table {
  border-spacing: 0;
  width: -webkit-max-content;
  width: max-content;
  min-width: 100%;
  font-size: 1.20em; }

#report-9 tbody tr {
  background-color: #fff; }

#report-9 thead {
  color: white; }

#report-9 th {
  font-weight: normal;
  font-weight: initial;
  height: 2rem;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #3f3f3f;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff; }
  #report-9 th.month-headers {
    width: 155px;
    text-align: center; }
  #report-9 th:first-child {
    width: 200px; }
  #report-9 th:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 0; }

#report-9 td {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  border: 1px solid #ddd; }
  #report-9 td.thirdCol {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 2; }
  #report-9 td.conference {
    background-color: #ddd;
    text-transform: uppercase; }
  #report-9 td.thirdCol span {
    display: block; }
    #report-9 td.thirdCol span:first-child {
      font-weight: bold;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
      #report-9 td.thirdCol span:first-child span.footnotesIndex {
        display: inline-block;
        font-weight: normal; }
        #report-9 td.thirdCol span:first-child span.footnotesIndex span {
          display: inline-block; }
  #report-9 td.segmentTd {
    padding: 0; }
    #report-9 td.segmentTd div {
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; }
    #report-9 td.segmentTd p {
      margin: auto;
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      font-weight: bold;
      font-style: italic;
      text-align: center; }
    #report-9 td.segmentTd span {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      display: block;
      line-height: 0.7rem; }
  #report-9 td.event {
    background-color: #f4cccc;
    font-weight: bold;
    padding: 0.3rem;
    max-width: 155px;
    word-break: break-all; }
  #report-9 td.event-continue-line {
    position: relative; }
    #report-9 td.event-continue-line::after {
      content: "-----------------";
      color: #3f3f3f;
      font-size: 2rem;
      letter-spacing: 5px;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: 5px;
      line-height: 2px;
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0; }
    #report-9 td.event-continue-line.end::before {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: calc(50% - 9px);
      right: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 12px solid #696969; }

#report-9 .eventTypes {
  margin-top: 10px;
  max-width: 650px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  position: -webkit-sticky;
  position: sticky;
  left: 0; }
  #report-9 .eventTypes p {
    width: 150px;
    text-align: center;
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 5px;
    padding: 5px; }

#report-9 .bg-blue {
  background-color: #002060; }

#report-9 .bg-red {
  background-color: #c00000; }

#report-9 .bg-white {
  background-color: white; }

#report-9 td.center {
  text-align: center; }

#report-9 .actionBtn {
  display: flexbox;
  text-align: right; }

#report-9 .actionBtn div {
  display: -webkit-inline-flex;
  display: inline-flex; }

#report-9 .filterContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px; }
  #report-9 .filterContainer > * {
    margin: 0 10px; }

#report-9 #footnotes-check {
  float: right; }

#report-9 .button.hidden {
  display: none; }

.dropzone-container {
  margin: 10px; }
  .dropzone-container .dropzone {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out; }
    .dropzone-container .dropzone:focus {
      border-color: #2196f3; }
  .dropzone-container .image-preview {
    margin: 10px; }
    .dropzone-container .image-preview div {
      display: inline-block;
      position: relative; }
      .dropzone-container .image-preview div img {
        max-width: 200px; }
    .dropzone-container .image-preview .delete-button {
      margin-left: 10px; }

#categoryInput {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%; }
  #categoryInput .categoryFormControl {
    width: calc(100% - 55px);
    text-align: left; }
  #categoryInput .addButton {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }

#addCompanyButton {
  position: fixed;
  bottom: 4rem;
  right: 4rem; }

#companyLogoManagerContainer {
  padding: 0 20px; }

.logoCard {
  min-width: 196px;
  max-width: 290px;
  height: 120px; }

.hidden {
  visibility: hidden; }

.ta-screen-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #323232; }

.client-subheader {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  padding: 10px;
  margin: 0 0 0 5px; }

.optionsContainer {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer; }

.optionsLink:hover {
  color: #B22028; }

.optionTag {
  font-family: Poppins;
  margin-left: 10px; }

.optionsPopover {
  padding: 5px;
  max-width: 220px; }

.card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  margin-top: 12px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; }

.single-client {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 231px;
  min-width: 240px;
  max-width: 240px;
  color: #fff;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 20px;
  cursor: pointer;
  background-color: #4D4F54; }
  .single-client .client-info {
    margin-top: auto;
    border-radius: 0px 0px 6px 6px;
    background-color: #4D4F54;
    padding: 10px;
    font-family: 'Poppins'; }

.hidden {
  visibility: hidden; }

.sources-box {
  border-bottom: 1px solid #ddd;
  padding: 5px; }
  .sources-box > svg {
    margin: auto 3px;
    cursor: pointer; }

.sourceInputContainer input {
  border: none;
  padding: 10px;
  font-family: 'Poppins';
  width: 100%; }
  .sourceInputContainer input::-webkit-input-placeholder {
    color: '#938D8D'; }
  .sourceInputContainer input::placeholder {
    color: '#938D8D'; }

.sources-wrapper-draftnews {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 40px;
  text-align: center; }

.visibility-icon-draftnews {
  margin-bottom: 8px; }

.visibility-switch-rapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row; }

.actionstate-headertable-wapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around; }

.link-icon-draftnews {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 16px; }

.src-keyword-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.edit-delete-btn-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.source-name-draftnews {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #B22028;
  text-decoration: underline; }

.sources-legend {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4D4F54;
  margin: 0 10px; }

.sourcelink-icon-wrapper {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.title-wrapper {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

#home {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-content: center;
          align-content: center;
  font-size: 2rem; }

.diseases-screen-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #323232; }

.background-single-disease {
  background-color: #B22028;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer; }

.disease-card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  margin: 12px 16px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: flex-start; }

.edit-delete-disease {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  left: 87%;
  top: 200px; }

.single-disease {
  position: relative;
  display: block;
  min-height: 231px;
  color: #fff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 20px; }
  .single-disease a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background-color: #B22028;
    height: 30%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    transition: 0.5s;
    border-radius: 0px 0px 6px 6px; }

.diseaseOptionsLink {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-direction: column;
          flex-direction: column; }

.diseaseOptionsLink:hover {
  color: #fff; }

.skeleton-p span span {
  margin: 1%; }

.card-container {
  position: relative;
  display: block;
  z-index: 0; }
  .card-container:hover {
    background: #8c8c8c; }
  .card-container:hover > .cardContent {
    background: #8c2626; }
  .card-container:hover > .optionsButton {
    visibility: visible; }
  .card-container:hover > .optionsButton:hover {
    background: white; }

.cardContent {
  background: #AE2F2F; }

.single-disease:hover > a {
  background: #7F1E22; }

.optionsButton {
  visibility: hidden; }

.single-disease:hover > .edit-delete-disease {
  visibility: visible; }

.hidden {
  visibility: hidden; }

.users-container {
  padding: 5 10px;
  font-family: "Poppins"; }
  .users-container #userSearch {
    margin-right: 0; }
    @media (max-width: 960px) {
      .users-container #userSearch {
        margin: auto 0;
        width: 100%; } }
  .users-container .roundButton {
    margin-left: auto; }
  .users-container .userTable {
    font-family: 'Poppins';
    margin: 20px 0;
    border-radius: 10px; }
    .users-container .userTable .permissionsCell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px; }
    .users-container .userTable .buttonUserAction {
      background-color: transparent;
      border: none; }
  @media (max-width: 960px) {
    .users-container .userHeader h1 {
      font-size: 28px; }
    .users-container .userSubHeader h3 {
      font-size: 24px;
      padding: 0;
      margin: auto 0; } }

.buttonUserAction {
  background-color: transparent;
  border: none; }

.userCard {
  width: 80%;
  margin: 0 auto;
  padding: 25px; }
  .userCard input {
    background-color: #F9F6F6; }
  .userCard h1 {
    text-align: center; }
  .userCard p {
    margin: 0 0 5px 0; }
  .userCard .userLabelContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-items: center;
            align-items: center; }
  .userCard .userLabel {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #b22028; }
  @media (max-width: 960px) {
    .userCard .userLabelContainer {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 960px) {
    .userCard {
      width: 100%;
      font-size: 10px; }
      .userCard h1 {
        font-size: 35px; } }

.roundButton.MuiButtonBase-root span.text {
  font-family: Poppins;
  text-transform: none;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.015em; }

.permission-field {
  position: relative; }
  .permission-field input {
    padding: 15px;
    padding-right: 80px; }
  .permission-field span {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #b22028;
    cursor: pointer;
    display: block; }

.userPermissionsCard {
  padding: 20px 0; }
  .userPermissionsCard .modalHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-align: unset;
    padding: 0px 20px 0 20px !important; }
    .userPermissionsCard .modalHeader .roundButton svg {
      color: #b22028; }
  .userPermissionsCard .modalFooter {
    margin-top: 20px; }
  @media (max-width: 960px) {
    .userPermissionsCard .modalHeader h2 {
      font-size: 15px;
      margin: auto 0; }
    .userPermissionsCard .modalHeader button {
      font-size: 10px; } }

.permissionsList {
  font-size: 10px; }
  .permissionsList .MuiListItemText-root span {
    font-family: "Poppins"; }
  .permissionsList > .MuiListItem-root {
    background: rgba(196, 196, 196, 0.1);
    border-bottom: 1px solid #ddd;
    padding-left: 25px; }
    .permissionsList > .MuiListItem-root svg {
      visibility: hidden; }
    .permissionsList > .MuiListItem-root:hover > svg {
      visibility: visible; }
  .permissionsList .nested {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 30px;
    background: #fff;
    border-bottom: 1px solid #ddd; }
    .permissionsList .nested > .MuiListItemText-root span {
      -webkit-transform: translateX(40px);
              transform: translateX(40px);
      font-family: "Poppins"; }
  @media (max-width: 960px) {
    .permissionsList .MuiListItemText-root span {
      font-size: 10px; } }

.selectedPermissions {
  margin-top: 40px;
  border-radius: 9px; }
  .selectedPermissions h3 {
    background: #b22028;
    padding: 12px 15px;
    color: #fff; }
  .selectedPermissions p {
    color: #8A8A8A;
    padding: 20px;
    margin: 0; }
  @media (max-width: 960px) {
    .selectedPermissions {
      font-size: 10px;
      margin-top: 10px; }
      .selectedPermissions h3 {
        font-size: 15px; } }

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block; }

.view-more-container {
  display: inline-block;
  /* white-space: nowrap; */
  overflow: hidden; }

.root-radios-user-status {
  padding-left: 20px; }

.root-radios-user-status .MuiFormControlLabel-label {
  /* Button 2 */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.015em;
  /* Gray Title */
  color: #8A8A8A; }

.status-active {
  color: #F7A458; }

.status-blocked {
  color: #b22028; }

.capitalize {
  text-transform: capitalize; }

.permission-container h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #323232; }

.permission-container p {
  /* Body Text 1 */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8A8A8A; }

.hidden {
  visibility: hidden; }

.hidden {
  visibility: hidden; }

.changePassword {
  margin: -24px;
  background-size: cover;
  height: calc(100vh - 90px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Poppins"; }
  .changePassword section {
    margin: auto 0; }

.pageHeader {
  background-color: #fff;
  padding: 15px 20px; }
  @media (max-width: 1280px) {
    .pageHeader {
      margin: 15px; } }
  .pageHeader h1 {
    margin: 0;
    display: inline-block;
    cursor: pointer; }
    .pageHeader h1 svg {
      -webkit-transform: translate(0, -3px);
              transform: translate(0, -3px); }

.cpContent {
  width: 310px;
  margin: 20px auto 0 auto;
  color: #8A8A8A; }
  .cpContent .cpTitle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .cpContent .cpTitle h2 {
      margin: auto 0; }
  .cpContent .cpForm {
    width: 240px;
    margin: 25px auto; }
    .cpContent .cpForm label {
      margin: 0.7rem 0 0 0; }
    .cpContent .cpForm .cpAction {
      margin-top: 25px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly; }
  .cpContent .auth-inputs {
    margin: 10px 0;
    background: #F4F4F4;
    transition: 0.3s ease-in; }
    .cpContent .auth-inputs fieldset {
      display: none; }

button.btnFilled {
  background-color: #b22028;
  color: white;
  font-weight: 600; }
  button.btnFilled:hover {
    background-color: #b22028; }

.hidden {
  visibility: hidden; }

.dashBg {
  background-image: url([object Module]);
  background-size: cover; }

.titleContainer {
  margin-bottom: 1rem;
  border-left: thick solid #b22028;
  padding-left: 20px; }

.userPopover {
  max-width: 220px;
  font-family: 'Poppins';
  text-align: center;
  padding-bottom: 10px; }
  .userPopover .info {
    padding: 20px 20px 0 20px; }
    .userPopover .info h3 {
      color: #b22028; }
    .userPopover .info h4 {
      margin-top: 5px; }
  .userPopover nav a {
    color: #8A8A8A; }
    .userPopover nav a:hover {
      color: #4D4F54; }
  .userPopover > a button {
    border: 2px solid;
    font-weight: 600; }
    .userPopover > a button:hover {
      border: 2px solid; }

#appBarSpacer {
  min-height: 90px; }

@media print {
  @page {
    size: auto; }
  main {
    height: unset !important;
    padding: unset !important; }
  #atacanaAppBar,
  #appBarSpacer,
  #atacanaDrawer,
  #download,
  .actionBtn,
  .no-print,
  .reportSelect,
  #footnotes-check,
  #footnotes-viewtype-toggle,
  hr {
    display: none !important; }
  #printMarginFix {
    margin-left: 0 !important; }
  body * {
    visibility: hidden !important; }
  #toImage,
  .titleContainer,
  .titleContainer *,
  #toImage * {
    visibility: visible !important; } }

.hidden {
  visibility: hidden; }

.auth-pages-layout {
  text-align: center; }
  @media (max-width: 600px) {
    .auth-pages-layout {
      overflow-y: scroll; } }
  .auth-pages-layout .auth-pages-top-bar {
    background-color: #fff;
    margin-top: 75px;
    width: 100%; }
  .auth-pages-layout .auth-card {
    background-color: rgba(0, 0, 0, 0.55);
    color: white;
    margin: 75px auto 20px auto;
    display: -webkit-flex;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    width: 360px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    overflow-y: inherit;
    font-size: calc(10px + 2vmin);
    min-height: 370px; }
  .auth-pages-layout .welcome-message h4 {
    color: white;
    line-height: 36px;
    font-size: 24px; }
  .auth-pages-layout .reset-link {
    color: #b22028;
    font-size: 12px;
    text-decoration: underline;
    font-weight: 600; }
  .auth-pages-layout .reg-invite {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
    color: #CCC3C3; }
    .auth-pages-layout .reg-invite a {
      color: #b22028;
      text-decoration: underline; }

form.auth-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  form.auth-form .authFormControl {
    width: 300px;
    margin: 0 auto; }
  form.auth-form .auth-inputs {
    margin: 10px 0;
    background: rgba(255, 255, 255, 0.2);
    transition: 0.3s ease-in; }
    form.auth-form .auth-inputs fieldset {
      display: none; }
    form.auth-form .auth-inputs input {
      color: #BFBFBF; }
    form.auth-form .auth-inputs.Mui-focused {
      background-color: #fff; }
      form.auth-form .auth-inputs.Mui-focused input {
        color: #323232; }
  form.auth-form .error-message {
    color: white; }

#reset-password .auth-inputs {
  margin: 10px 0 0 0; }

.instruction-text {
  padding: 0 10px; }
  .instruction-text .punch-line {
    color: #b22028;
    font-weight: 600;
    font-size: 16px; }
  .instruction-text p {
    font-size: 14px; }

#login .auth-warning {
  color: #b22028;
  font-size: 12px; }

button.accessBtn {
  color: #fff;
  margin: 25px auto;
  display: block;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
  text-transform: unset;
  background-color: #b22028;
  padding: 5px 25px; }

.hidden {
  visibility: hidden; }

.atButton {
  border: none;
  border-radius: 3px;
  padding: 8px 25px;
  color: #b22028;
  background-color: #fff;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
  white-space: nowrap; }
  .atButton:hover {
    background-color: whitesmoke; }
  .atButton:focus {
    -webkit-filter: brightness(90%);
            filter: brightness(90%); }
  .atButton svg {
    display: inline-block; }
  .atButton.fill {
    color: white;
    background-color: #b22028; }
    .atButton.fill:hover {
      background-color: #9c1c23; }
  .atButton.bordered {
    border: 2px solid #b22028; }
  .atButton.medium {
    padding: 7px 23px; }
  .atButton.small {
    padding: 5px 20px; }

